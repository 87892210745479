import { useTagManager } from 'firebase-client';
import logger, { initLogger } from 'logger';
import Head from 'next/head';
import { useTranslation } from 'next-export-i18n';
import { useContext, useEffect } from 'react';
import { initRum } from 'rum';
import { Navbar } from 'ui';

import { ChooseGenerator } from '~/components/ChooseGenerator';
import { ChoosePlanType } from '~/components/ChoosePlanType';
import { ProjectContext } from '~/context';
import { useOnboardingStore } from '~/store';

initRum({ service: 'onboarding' });
initLogger({ service: 'onboarding' });

const Onboarding = () => {
  const { t } = useTranslation();

  const { state } = useContext(ProjectContext);
  const { user, initUser } = useOnboardingStore();

  useTagManager({
    gtmId: process.env.NEXT_PUBLIC_GTM_CONFIG!,
  });

  useEffect(() => {
    if (!user) {
      initUser();
    }
    logger.debug('User', { user });
  }, [initUser, user]);

  return (
    <>
      <Head>
        <title>{t('onboarding.title')}</title>
      </Head>
      <Navbar />

      {state.plan_type === null && <ChoosePlanType />}
      {state.plan_type && <ChooseGenerator />}
    </>
  );
};

export default Onboarding;
