import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { app } from './app';
import { db } from './db/db';

export const functions = getFunctions(app, 'europe-west1');

if (typeof window !== 'undefined' && window.location.href.indexOf('localhost') > -1) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
}

export * from './analytics';
export * from './auth';
export * from './db';
export * from './services';
