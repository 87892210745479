import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { User } from 'types';

import { createCollection, db } from '../db';

const usersCollection = createCollection<User>('users');
export const saveUser = async (user: Omit<User, 'createdOn'>): Promise<User> => {
  const savedUser = {
    ...user,
    createdOn: Timestamp.now(),
  };
  await setDoc(doc(db, 'users', user.userId), savedUser);
  return savedUser;
};

export const updateUser = (user: Partial<User>) => {
  return updateDoc(doc(usersCollection, user.userId), user);
};
