import { createRequest, updateRequestFields } from 'firebase-client';
import logger from 'logger';
import { isPartiallyCompletedRequest } from 'types';
import { StateCreator } from 'zustand';

import { InitializedRequestValues, RequestSlice, UserSlice } from '../types';

const initialState: InitializedRequestValues = {
  completed: false,
};
export const createRequestSlice: StateCreator<UserSlice & RequestSlice, [], [], RequestSlice> = (set, get) => ({
  completeRequest: async (request) => {
    const currentForm = get().form;

    if (!currentForm) {
      throw new Error(`Can't complete request - it doesn't exist`);
    }

    const completedRequest = { ...currentForm, ...request, completed: true };

    if (!isPartiallyCompletedRequest(completedRequest)) {
      logger.error('error completing request - request not completed');
      throw new Error('Request not completed');
    }

    try {
      await updateRequestFields(currentForm.requestId, { ...completedRequest });

      set({ form: completedRequest });

      return completedRequest;
    } catch (error) {
      logger.error('error completing request', { error });
      throw error;
    }
  },
  form: null,
  initRequest: async (request) => {
    const { user, form: currentForm } = get();

    if (!user) {
      throw new Error(`User not authenticated`);
    }

    if (currentForm) {
      throw new Error(`Form already initialized`);
    }

    const form = { ...initialState, userId: user.userId, ...request };

    try {
      const { id, createdOn } = await createRequest(form);
      await updateRequestFields(id, {
        requestId: id,
      });
      set({
        form: {
          createdOn,
          requestId: id,
          ...form,
        },
      });
    } catch (error) {
      logger.error('error creating request', { error });
      throw error;
    }
  },

  updateRequest: async (newRequest) => {
    const currentForm = get().form;

    if (!currentForm?.requestId) {
      throw new Error(`unable to update non existing request`);
    }
    const form = { ...currentForm, ...newRequest };

    try {
      await updateRequestFields(currentForm.requestId, newRequest);
      set({ form });
    } catch (error) {
      logger.error('error updating request', { error });
      throw error;
    }
  },
});
