import { AnalyticsEvents, trackEvent } from 'firebase-client';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import { useContext } from 'react';
import { Actions, addAction } from 'rum';
import { Box, Button, Container, Grid, Paper, Toggle, Typography, useTheme } from 'ui';

import { CHANGE_PRODUCER_TYPE, CHANGE_PROJECT_TYPE, ProjectContext } from '~/context';

export const ChooseGenerator = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const router = useRouter();

  const { state, dispatch } = useContext(ProjectContext);

  const onChangeHandler = () => {
    const newProducerType = state.producer_type === 'Producer' ? 'Prosumer' : 'Producer';
    dispatch({
      payload: newProducerType,
      type: CHANGE_PRODUCER_TYPE,
    });

    addAction(Actions.ONBOARDING_SELECT_CONSUMER_TYPE, {
      consumerType: newProducerType,
    });
  };

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, type: ProjectType) => {
    e.preventDefault();

    dispatch({ payload: type, type: CHANGE_PROJECT_TYPE });

    addAction(Actions.ONBOARDING_SELECT_PROJECT_TYPE, {
      projectType: type,
    });

    router.push('/request');

    trackEvent(AnalyticsEvents.IppFormStage, {
      generatorStage: type,
      generatorType: state.producer_type,
      planName: state.plan_type,
    });
  };

  const cardsData = [
    {
      content: t('onboarding.cardsData.producingContent'),
      svg: '/images/working_vei.svg',
      title: t('onboarding.cardsData.producing'),
      type: 'working',
    },
    {
      content: t('onboarding.cardsData.startupContent'),
      svg: '/images/inprogress_vei.svg',
      title: t('onboarding.cardsData.startup'),
      type: 'inProgress',
    },
    {
      content: t('onboarding.cardsData.infoContent'),
      svg: '/images/new_electro.svg',
      title: t('onboarding.cardsData.info'),
      type: 'vei',
    },
  ];

  return (
    <Container>
      <Grid container justifyContent="center">
        <Box maxWidth={theme.spacing(95)}>
          <Typography variant="h4" color="primary.main" textAlign="center">
            {t('onboarding.common.offerRequest')}
          </Typography>
          <Typography color="info.light" variant="h6" my={2} textAlign="center">
            {t('onboarding.common.offerInfo')}
          </Typography>
        </Box>
        <Grid container columns={18} my={4}>
          <Grid item xs={18} width="100%" mx="auto">
            <Toggle
              onChangeHandler={onChangeHandler}
              options={[t('onboarding.producerType.generator'), t('onboarding.producerType.prosumer')]}
              checked={state.producer_type === 'Prosumer'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={theme.spacing(2)} columns={18}>
          {cardsData.map(({ type, svg, title, content }) => (
            <Grid item xs={18} md={9} lg={6} key={title}>
              <Paper key={type} data-testid={`producer-type-${type}`}>
                <Grid container justifyContent="center" direction="row" spacing={theme.spacing(2)}>
                  <Grid item height={theme.spacing(15)}>
                    <Box alignContent="center">
                      <img src={svg} alt={title} width="200px" height="100px" />
                    </Box>
                  </Grid>
                  <Grid item height={theme.spacing(12)}>
                    <Typography variant="h5" color="info" textAlign="center">
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item height={theme.spacing(22)}>
                    <Typography color="info.light" textAlign="center">
                      {content}
                    </Typography>
                  </Grid>
                  <Grid item width="100%">
                    <Button data-testid="continue" onClick={(e) => onClickHandler(e, type as ProjectType)} fullWidth>
                      {t('shared.common.next')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
