import { StateCreator } from 'zustand';

import { StepperSlice } from '../types';

export const createStepperSlice = <Steps>() => {
  const slice: StateCreator<StepperSlice<Steps>, [], [], StepperSlice<Steps>> = (set) => ({
    setStep: (step) => set({ step }),
    step: null,
  });

  return slice;
};
