import TagManager from 'react-gtm-module';

export enum OnboardingEvents {
  email = 'onboarding_email',
  phoneNumber = 'onboarding_phoneNumber',
  password = 'onboarding_password',
  openHomePage = 'onboarding_openHomePage',
  submitAccount = 'onboarding_submitAccount',
  businessCustomerType = 'onboarding_businessCustomerType',
  residentialCustomerType = 'onboarding_residentialCustomerType',
  fillDataByInvoice = 'onboarding_fillDataByInvoice',
  fillDataManually = 'onboarding_fillDataManually',
  uploadInvoice = 'onboarding_uploadInvoice',
  customerName = 'onboarding_customerName',
  companyEIK = 'onboarding_companyEIK',
  personalId = 'onboarding_personalId',
  companyName = 'onboarding_companyName',
  representative = 'onboarding_representative',
  customerAddress = 'onboarding_customerAddress',
  billingData = 'onboarding_billingData',
  contactData = 'onboarding_contactData',
  submitCustomer = 'onboarding_submitCustomer',
  meteringPointName = 'onboarding_meteringPointName',
  meteringPointAddress = 'onboarding_meteringPointAddress',
  meteringPointId = 'onboarding_meteringPointId',
  submitMeteringPoints = 'onboarding_submitMeteringPoints',
  contractPreview = 'onboarding_contractPreview',
  contractSign = 'onboarding_contractSign',
  contractDownload = 'onboarding_contractDownload',
  submitContract = 'onboarding_submitContract',
}

export enum BlenderEvents {
  invoices = 'blender_invoices',
  customers = 'blender_customers',
  meteringPoints = 'blender_meteringPoints',
  contracts = 'blender_contracts',
  tokiGpt = 'blender_tokiGpt',
  invoiceDetails = 'blender_invoiceDetails',
  invoiceSearch = 'blender_invoiceSearch',
  invoiceOrderBy = 'blender_invoiceOrderBy',
  customerDetails = 'blender_customerDetails',
  customerSearch = 'blender_customerSearch',
  customerInvoiceDetails = 'blender_customerInvoiceDetails',
  customerMeteringPointDetails = 'blender_customerMeteringPointDetails',
  meteringPointDetails = 'blender_meteringPointDetails',
  meteringPointSearch = 'blender_meteringPointSearch',
  meteringPointInvoiceDetails = 'blender_meteringPointInvoiceDetails',
  askTokiGpt = 'blender_askTokiGpt',
}

export const trackTagManagerEvent = async (
  event: OnboardingEvents | BlenderEvents,
  parameters?: Record<string, unknown>
) => {
  TagManager.dataLayer({
    dataLayer: { event, ...parameters },
  });
};
