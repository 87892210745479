import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import logger from 'logger';

export enum AnalyticsEvents {
  sFormMailingData = 'sForm_mailingData',
  sFormContractInfo = 'sForm_contractInfo',
  sFormMeterPoint = 'sForm_meterPoint',
  sFormContractDownload = 'sForm_contractDownload',
  sFormFileUpload = 'sForm_fileUpload',
  sFormSubmit = 'sForm_submit',
  IppFormStage = 'ippForm_stage',
  IppFormInfo = 'ippForm_info',
  IppFormSubmit = 'ippForm_submit',
}

export const trackEvent = async (event: AnalyticsEvents, parameters: Record<string, unknown>) => {
  const analytics: Analytics | undefined = process.env.NEXT_PUBLIC_FIREBASE_CONFIG ? getAnalytics() : undefined;

  if (analytics) {
    logEvent(analytics, event, parameters);
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logger.info(event, parameters as any);
};

export { BlenderEvents, OnboardingEvents, trackTagManagerEvent } from './trackTagManagerEvent';
export { useTagManager } from './useTagManager';
