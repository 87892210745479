import logger from 'logger';
import { isValidEnvironment } from 'types';

const V2_FUNCTION_REGION = 'europe-west1';
const V2_FUNCTION_HASH = {
  development: 'r5wewyxvfa',
  local: 'rf5hshftoa',
  production: 'b5jyiky5zq',
};

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT?.trim();
const PROJECTS = {
  development: 'toki-apps-dev',
  local: 'toki-apps-local',
  localhost: 'toki-apps-local',
  production: 'toki-apps-prod',
};

export const getApiUrl = (
  name: 'uploaddocumentbytype' | 'generatedocument' | 'storeswitchingrequesttosheets' | 'sendmail'
) => {
  if (!isValidEnvironment(environment)) {
    logger.error('NEXT_PUBLIC_ENVIRONMENT is missing or incorrect', { environment });
    throw new Error('NEXT_PUBLIC_ENVIRONMENT is missing or incorrect');
  }

  if (environment === 'localhost') {
    return `http://localhost:5001/${PROJECTS[environment]}/${V2_FUNCTION_REGION}/${name}`;
  }

  return `https://${name}-${V2_FUNCTION_HASH[environment]}-ew.a.run.app`;
};
