import { HTTP_CONTENT_TYPES, RequestTypes } from 'types';

import { getApiUrl } from '../modules/getApiUrl/getApiUrl';

const generateDocument = (type: RequestTypes.switching, requestId: string) =>
  fetch(getApiUrl('generatedocument'), {
    body: JSON.stringify({ requestId, type }),
    headers: {
      'Content-Type': HTTP_CONTENT_TYPES.JSON,
    },
    method: 'POST',
  });

const uploadDocumentByType = (formData: FormData) =>
  fetch(getApiUrl('uploaddocumentbytype'), {
    body: formData,
    method: 'POST',
  });

export { generateDocument, uploadDocumentByType };
