import { signInAnonymously } from 'firebase/auth';
import { auth, saveUser } from 'firebase-client';
import { addError, Errors, setUser } from 'rum';
import { isError, User } from 'types';

export const authenticate = async (): Promise<User> => {
  try {
    const userCredentials = await signInAnonymously(auth);
    const user: Omit<User, 'createdOn'> = { userId: userCredentials.user.uid };

    const savedUser = await saveUser(user);

    setUser({
      email: savedUser.email || '',
      id: savedUser.userId,
    });

    return savedUser;
  } catch (error) {
    if (isError(error)) {
      addError(Errors.AUTH_ANONYMOUS_SIGN_IN_FAILURE, error);
    }
    throw new Error(`Unknown error: ${JSON.stringify(error)}`);
  }
};
