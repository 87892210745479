import { authenticate } from 'auth';
import { updateUser } from 'firebase-client';
import { User } from 'types';
import { StateCreator } from 'zustand';

import { RequestSlice, UserSlice } from '../types';

export const createUserSlice: StateCreator<UserSlice & RequestSlice, [], [], UserSlice> = (set, get) => ({
  initUser: async () => {
    const { user: currentUser } = get();

    if (currentUser) {
      throw new Error(`User ${currentUser} already exists`);
    }
    const user = await authenticate();
    set({ user });
  },
  updateUser: async (data) => {
    const { user: currentUser } = get();

    if (!currentUser) {
      throw new Error('Unable to update uninitialized user.');
    }

    const updatedUser: User = { ...currentUser, ...data };

    await updateUser(updatedUser);

    set({ user: updatedUser });
  },
  user: null,
});
