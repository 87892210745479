import { addDoc, doc, FieldValue, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import logger from 'logger';
import {
  HTTP_CONTENT_TYPES,
  OnboardingRequest,
  PartiallyCompletedSwitchingRequest,
  RequestTypes,
  SwitchingRequest,
} from 'types';

import { createCollection } from '../db';
import { getApiUrl } from '../modules/getApiUrl/getApiUrl';

const collection = createCollection<SwitchingRequest | Omit<OnboardingRequest, 'requestId'>>('requests');

const createSimpleOnboardingRequest = async (request: Omit<OnboardingRequest, 'type' | 'completed' | 'requestId'>) => {
  logger.debug('createSimpleOnboardingRequest', request);
  return addDoc(collection, {
    ...request,
    completed: true,
    createdOn: Timestamp.now(),
    type: RequestTypes.onboarding,
  });
};

const getSwitchingRequest = async (requestId: string) => {
  const docRef = doc(collection, requestId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists() || docSnap.data().type !== RequestTypes.switching) {
    throw new Error('switching request not found');
  }
  return docSnap.data() as SwitchingRequest;
};

const createRequest = async (formData: Omit<SwitchingRequest, 'requestId'>) => {
  const createdOn = Timestamp.now();
  const request = {
    ...formData,
    createdOn,
  };
  const { id } = await addDoc(createCollection<Omit<SwitchingRequest, 'requestId'>>('requests'), request);
  return {
    createdOn,
    id,
  };
};

const updateRequestFields = async (
  docId: string,
  fields: { [x: string]: FieldValue | Partial<unknown> | null }
): Promise<void> => {
  const docRef = doc(collection, docId);
  return updateDoc(docRef, fields);
};

const handleCompletedRequest = async (currentForm: PartiallyCompletedSwitchingRequest) =>
  fetch(getApiUrl('storeswitchingrequesttosheets'), {
    body: JSON.stringify({ requestId: currentForm.requestId }),
    headers: { 'Content-Type': HTTP_CONTENT_TYPES.JSON },
    method: 'POST',
  });

const getRequestContractNumber = async (requestId: string): Promise<string | undefined> => {
  const { contractNumber } = await getSwitchingRequest(requestId);

  return contractNumber;
};

export {
  createRequest,
  createSimpleOnboardingRequest,
  getRequestContractNumber,
  getSwitchingRequest,
  handleCompletedRequest,
  updateRequestFields,
};
