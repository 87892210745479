import { connectAuthEmulator, getAuth, onAuthStateChanged } from 'firebase/auth';

import { app } from './app';

export const auth = getAuth(app);

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export { onAuthStateChanged };
