import { useTranslation } from 'next-export-i18n';
import { useContext } from 'react';
import { Actions, addAction } from 'rum';
import { Button, Container, Grid, Paper, Typography, useTheme } from 'ui';

import { CHANGE_PLAN_TYPE, ProjectContext } from '~/context';

export const ChoosePlanType = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { dispatch } = useContext(ProjectContext);

  const planCardsData = [
    {
      content: t('onboarding.planCardsData.standardContent'),
      svg: '/standard.svg',
      title: t('onboarding.planCardsData.standard'),
      type: 'standard',
    },
    {
      content: t('onboarding.planCardsData.exchangeContent'),
      svg: '/exchange.svg',
      title: t('onboarding.planCardsData.exchange'),
      type: 'exchange',
    },
    {
      content: t('onboarding.planCardsData.mixedContent'),
      svg: '/mixed.svg',
      title: t('onboarding.planCardsData.mixed'),
      type: 'mixed',
    },
  ];

  function selectPlanType(type: PlanType) {
    dispatch({ payload: type, type: CHANGE_PLAN_TYPE });
    document.body.scrollIntoView({ block: 'start' });
    addAction(Actions.ONBOARDING_SELECT_PLAN_TYPE, {
      planType: type,
    });
  }

  return (
    <Container>
      <Grid container alignItems="center">
        <Typography variant="h4" mb={5} textAlign="center">
          {t('onboarding.common.planRequest')}
        </Typography>

        <Grid container spacing={2}>
          {planCardsData.map((card) => (
            <Grid item xs={12} md={6} lg={6} xl={4} key={card.title}>
              <Paper key={card.title}>
                <Grid container justifyContent="center" direction="row" spacing={2}>
                  <Grid item height={theme.spacing(15)}>
                    <img src={card.svg} alt={card.title} width="100px" height="100px" />
                  </Grid>
                  <Grid item height={theme.spacing(10)} width="100%">
                    <Typography variant="h5" color="primary.main" textAlign="center">
                      {card.title}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      fontSize: theme.spacing(2),
                      height: theme.spacing(50),
                      textAlign: 'center',
                      [theme.breakpoints.down('lg')]: {
                        height: theme.spacing(40),
                      },
                    }}
                  >
                    {card.title === t('onboarding.planCardsData.mixed') && (
                      <Typography color="info.main">{t('onboarding.planCardsData.mixedTitle')}</Typography>
                    )}
                    <Typography color="info.light">{card.content}</Typography>
                  </Grid>
                  <Grid item height={theme.spacing(5)} width="100%">
                    <Button onClick={() => selectPlanType(card.type as PlanType)} fullWidth>
                      {t('shared.common.request')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
