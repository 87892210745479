import {
  create,
  createRequestSlice,
  createStepperSlice,
  createUserSlice,
  RequestSlice,
  StepperSlice,
  UserSlice,
} from 'store';

type OnboardingSteps = 'stationForm' | 'companyForm' | 'generateContract' | 'form' | 'success' | 'confirmation';
type OnboardingStore = StepperSlice<OnboardingSteps> & RequestSlice & UserSlice;
export const useOnboardingStore = create<OnboardingStore>()((...a) => ({
  ...createStepperSlice<OnboardingSteps>()(...a),
  ...createRequestSlice(...a),
  ...createUserSlice(...a),
}));
